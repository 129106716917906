import React, { useState, useEffect } from "react";
import { Row, Col } from "shards-react";
import { getScoreClass } from "../../helpers/scores";
import axios from "axios";
import { API_URL_PSI } from "../../constants";
import CircularProgress from "@mui/material/CircularProgress";
import { calculateDifference } from "../../helpers/scores";

const LabDataComparison = ({ siteUrl, mainSiteUrl }) => {
  const [labDataMobile, setLabDataMobile] = useState([]);
  const [labDataDesktop, setLabDataDesktop] = useState([]);
  const [loadingLabData, setLoadingLabData] = useState(true);

  const [mainSitelabDataMobile, setMainSiteLabDataMobile] = useState([]);
  const [mainSitelabDataDesktop, setMainSiteLabDataDesktop] = useState([]);
  const [mainSiteloadingLabData, setMainSiteLoadingLabData] = useState(true);

  const [comparedData, setComparedData] = useState([]);
  const [comparedDataDesktop, setComparedDataDesktop] = useState([]);

  const getLatestData = async (siteUrl) => {
    try {
      const response = await axios.get(
        `${API_URL_PSI}/scan/latest`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let data = response.data;
      setLabDataMobile([
        {
          FCP: data.mobile.labData.fcp,
          LCP: data.mobile.labData.lcp,
          SI: data.mobile.labData.si,
          CLS: data.mobile.labData.cls,
          TBT: data.mobile.labData.tbt,
        },
      ]);
      setLabDataDesktop([
        {
          FCP: data.desktop.labData.fcp,
          LCP: data.desktop.labData.lcp,
          SI: data.mobile.labData.si,
          CLS: data.desktop.labData.cls,
          TBT: data.desktop.labData.tbt,
        },
      ]);
      setLoadingLabData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getMainSiteLatestData = async (mainSiteUrl) => {
    try {
      const response = await axios.get(
        `${API_URL_PSI}/scan/latest`,
        {
          params: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let data = response.data;
      setMainSiteLabDataMobile([
        {
          FCP: data.mobile.labData.fcp,
          LCP: data.mobile.labData.lcp,
          SI: data.mobile.labData.si,
          CLS: data.mobile.labData.cls,
          TBT: data.mobile.labData.tbt,
        },
      ]);
      setMainSiteLabDataDesktop([
        {
          FCP: data.desktop.labData.fcp,
          LCP: data.desktop.labData.lcp,
          SI: data.mobile.labData.si,
          CLS: data.desktop.labData.cls,
          TBT: data.desktop.labData.tbt,
        },
      ]);
      setMainSiteLoadingLabData(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    await getLatestData(siteUrl);
  }, [siteUrl]);

  useEffect(async () => {
    await getMainSiteLatestData(mainSiteUrl);
  }, [mainSiteUrl]);

  useEffect(() => {
    if (labDataMobile.length && mainSitelabDataMobile.length) {
      let dataToCompare = [];
      let comparedData = [];
      let labDataMobileValues = Object.values(labDataMobile[0]);
      let mainSitelabDataMobileValues = Object.values(mainSitelabDataMobile[0]);
      labDataMobileValues.map((smallItem, smallIndex) => {
        mainSitelabDataMobileValues.map((mainItem, mainIndex) => {
          if (smallIndex === mainIndex) {
            if (smallItem.includes("s") && !smallItem.includes("ms")) {
              dataToCompare.push([
                +smallItem.split("s")[0],
                +mainItem.split("s")[0],
              ]);
            }
            if (smallItem.includes("ms")) {
              dataToCompare.push([
                +smallItem.split("ms")[0],
                +mainItem.split("ms")[0],
              ]);
            }
            if (!smallItem.includes("s") && !smallItem.includes("ms")) {
              dataToCompare.push([+smallItem, +mainItem]);
            }
          }
        });
      });
      dataToCompare.forEach((item) => {
        const difference = calculateDifference(item[0], item[1]);
        comparedData.push(difference);
      });
      setComparedData(comparedData);
    }
    if (labDataDesktop.length && mainSitelabDataDesktop.length) {
      let dataToCompareDesktop = [];
      let comparedDataDesktop = [];
      let labDataDesktopValues = Object.values(labDataDesktop[0]);
      let mainSitelabDataDesktopValues = Object.values(
        mainSitelabDataDesktop[0]
      );
      labDataDesktopValues.map((smallItem, smallIndex) => {
        mainSitelabDataDesktopValues.map((mainItem, mainIndex) => {
          if (smallIndex === mainIndex) {
            if (smallItem.includes("s") && !smallItem.includes("ms")) {
              dataToCompareDesktop.push([
                +smallItem.split("s")[0],
                +mainItem.split("s")[0],
              ]);
            }
            if (smallItem.includes("ms")) {
              dataToCompareDesktop.push([
                +smallItem.split("ms")[0],
                +mainItem.split("ms")[0],
              ]);
            }
            if (!smallItem.includes("s") && !smallItem.includes("ms")) {
              dataToCompareDesktop.push([+smallItem, +mainItem]);
            }
          }
        });
      });
      dataToCompareDesktop.forEach((item) => {
        const difference = calculateDifference(item[0], item[1]);
        comparedDataDesktop.push(difference);
      });

      setComparedDataDesktop(comparedDataDesktop);
    }
  }, [
    labDataMobile,
    mainSitelabDataMobile,
    labDataDesktop,
    mainSitelabDataDesktop,
  ]);

  return (
    <>
      <Row>
        <h3 className="text-sm-left ml-3 heading-3">Lab Data</h3>
      </Row>

      <Row>
        <Col className="col-lg-6 col-md-6 col-sm-6 mb-4">
          <h4 className="text-sm-left heading-3">Mobile Devices</h4>
          {loadingLabData ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress
                style={{ margin: "2rem", height: "140px", width: "140px" }}
              />
              <div>Loading MetryV Score</div>
            </div>
          ) : (
            labDataMobile.map((metric, idx) => (
              <table className="table" key={idx}>
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Metric
                    </th>
                    <th
                      scope="col"
                      className="border-0"
                      style={{ textAlign: "right" }}
                    >
                      Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.FCP, "FCP", "mobile")
                        }
                      ></span>
                      First Contentful Paint
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.FCP, "FCP", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.FCP}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.LCP, "LCP", "mobile")
                        }
                      ></span>
                      Largest Contentful Paint
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.LCP, "LCP", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.LCP}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.SI, "SI", "mobile")
                        }
                      ></span>
                      Speed Index
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.SI, "SI", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.SI}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.CLS, "CLS", "mobile")
                        }
                      ></span>
                      Cumulative Layout Shift
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.CLS, "CLS", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.CLS}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.TBT, "TBT", "mobile")
                        }
                      ></span>
                      Total Blocking Time
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.TBT, "TBT", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.TBT}
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
          )}
          <h4 className="text-sm-left heading-3">Desktop Devices</h4>
          {loadingLabData ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress
                style={{ margin: "2rem", height: "140px", width: "140px" }}
              />
              <div>Loading MetryV Score</div>
            </div>
          ) : (
            labDataDesktop.map((metric, idx) => (
              <table className="table" key={idx}>
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Metric
                    </th>
                    <th
                      scope="col"
                      className="border-0"
                      style={{ textAlign: "right" }}
                    >
                      Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.FCP, "FCP", "mobile")
                        }
                      ></span>
                      First Contentful Paint
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.FCP, "FCP", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.FCP}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.LCP, "LCP", "mobile")
                        }
                      ></span>
                      Largest Contentful Paint
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.LCP, "LCP", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.LCP}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.SI, "SI", "mobile")
                        }
                      ></span>
                      Speed Index
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.SI, "SI", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.SI}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.CLS, "CLS", "mobile")
                        }
                      ></span>
                      Cumulative Layout Shift
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.CLS, "CLS", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.CLS}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.TBT, "TBT", "mobile")
                        }
                      ></span>
                      Total Blocking Time
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.TBT, "TBT", "mobile")
                      }
                      style={{ textAlign: "right" }}
                    >
                      {metric.TBT}
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
          )}
        </Col>
        <Col className="col-lg-6 col-md-6 col-sm-6 mb-4">
          <h4 className="text-sm-left heading-3">Mobile Devices</h4>
          {mainSiteloadingLabData ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress
                style={{ margin: "2rem", height: "140px", width: "140px" }}
              />
              <div>Loading MetryV Score</div>
            </div>
          ) : (
            mainSitelabDataMobile.map((metric, idx) => (
              <table className="table" key={idx}>
                <thead className="bg-light">
                  <tr>
                    <th
                      scope="col"
                      className="border-0"
                      style={{ textAlign: "right" }}
                    ></th>
                    <th scope="col" className="border-0">
                      Score
                    </th>
                    <th
                      scope="col"
                      className="border-0"
                      style={{ textAlign: "right" }}
                    >
                      Metric
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="arrow-position">
                      {comparedData.length ? (
                        comparedData[0].differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedData[0]?.value ? "decrease" : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.FCP, "FCP", "mobile")
                      }
                    >
                      {metric.FCP}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      First Contentful Paint{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.FCP, "FCP", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedData.length ? (
                        comparedData[1].differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedData[1]?.value ? "decrease" : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.LCP, "LCP", "mobile")
                      }
                    >
                      {metric.LCP}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Largest Contentful Paint{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.LCP, "LCP", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedData.length ? (
                        comparedData[2].differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedData[2]?.value ? "decrease" : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedData.length ? (
                        comparedData[3].differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedData[3]?.value ? "decrease" : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.CLS, "CLS", "mobile")
                      }
                    >
                      {metric.CLS}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Cumulative Layout Shift{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.CLS, "CLS", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedData.length ? (
                        comparedData[3].differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedData[3]?.value ? "decrease" : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.SI, "SI", "mobile")
                      }
                    >
                      {metric.SI}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Speed Index{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.SI, "SI", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedData.length ? (
                        comparedData[3].differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedData[3]?.value ? "decrease" : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.TBT, "TBT", "mobile")
                      }
                    >
                      {metric.TBT}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Total Blocking Time{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.TBT, "TBT", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
          )}
          <h4 className="text-sm-left heading-3">Desktop Devices</h4>
          {mainSiteloadingLabData ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress
                style={{ margin: "2rem", height: "140px", width: "140px" }}
              />
              <div>Loading MetryV Score</div>
            </div>
          ) : (
            mainSitelabDataDesktop.map((metric, idx) => (
              <table className="table" key={idx}>
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0"></th>
                    <th scope="col" className="border-0">
                      Score
                    </th>
                    <th
                      scope="col"
                      className="border-0"
                      style={{ textAlign: "right" }}
                    >
                      Metric
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="arrow-position">
                      {comparedDataDesktop.length ? (
                        comparedDataDesktop[0]?.differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedDataDesktop[0]?.value
                                ? "decrease"
                                : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.FCP, "FCP", "mobile")
                      }
                    >
                      {metric.FCP}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      First Contentful Paint{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.FCP, "FCP", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedDataDesktop.length ? (
                        comparedDataDesktop[1]?.differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedDataDesktop[1]?.value
                                ? "decrease"
                                : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.LCP, "LCP", "mobile")
                      }
                    >
                      {metric.LCP}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Largest Contentful Paint{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.LCP, "LCP", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedDataDesktop.length ? (
                        comparedDataDesktop[2]?.differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedDataDesktop[2]?.value
                                ? "decrease"
                                : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedDataDesktop.length ? (
                        comparedDataDesktop[3]?.differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedDataDesktop[3]?.value
                                ? "decrease"
                                : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.CLS, "CLS", "mobile")
                      }
                    >
                      {metric.CLS}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Cumulative Layout Shift{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.CLS, "CLS", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedDataDesktop.length ? (
                        comparedDataDesktop[4]?.difference === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage stats-small__percentage--${
                              comparedDataDesktop[4]?.value
                                ? "decrease"
                                : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.SI, "SI", "mobile")
                      }
                    >
                      {metric.SI}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Speed Index{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.SI, "SI", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="arrow-position">
                      {comparedDataDesktop.length ? (
                        comparedDataDesktop[5]?.differnce === 0 ? (
                          <span
                            className={`stats-small__percentage stats-small__percentage--decrease arrow-position-item-left`}
                          ></span>
                        ) : (
                          <span
                            className={`stats-small__percentage--${
                              comparedDataDesktop[5]?.value
                                ? "decrease"
                                : "increase"
                            } arrow-position-item-left`}
                          ></span>
                        )
                      ) : null}
                    </td>
                    <td
                      className={
                        "score-text--" +
                        getScoreClass(metric.TBT, "TBT", "mobile")
                      }
                    >
                      {metric.TBT}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Total Blocking Time{" "}
                      <span
                        className={
                          "score score--" +
                          getScoreClass(metric.TBT, "TBT", "mobile")
                        }
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
          )}
        </Col>
      </Row>
    </>
  );
};

export default LabDataComparison;
