import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Container,
  FormSelect,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "shards-react";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PageTitle from "../components/common/PageTitle";
import TableDataMobile from "../components/ping/TableDataMobile";
import Table from "../components/ping/Table";
import TableMobile from "../components/ping/TableMobile";
import DeleteDataModal from "../components/ping/DeleteDataModal";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { getData } from "../state/actions/sites.actions";
import {
  getPingData,
  addNewPingData,
  getFilteredPingData,
} from "../state/actions/ping.actions";
import { getSitesStatusData } from "../state/actions/psi.actions";
import { useSnackbar } from "notistack";
import { getUserPlanData } from "../state/actions/user.actions";
import {
  getMetryVScoreData,
  getOriginSummaryData,
  getMonthlyOverallScoreData,
  getRankingData,
  getMonthlyLabData,
  getUptimeStatusData,
  getW3ValidatorData,
} from "../state/actions/psi.actions";
import { isMobile } from "react-device-detect";
import ChartsTable from "../components/ping/TableChartsNew";
import "../assets/styles/components/_range-date-picker.scss";
import classNames from "classnames";
import ReactModal from "react-modal";
import { InputGroup, DatePicker, InputGroupAddon } from "shards-react";
import DatePickerr from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";
import "../assets/styles/file.scss";
import xLogo from "../assets/images/logos/x.svg";
import { Portal } from "react-overlays";

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

const styles = {
  background: { xLogo },
  backgroundSize: "20px 20px",
  paddingRight: "100px",
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: "9999",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: "4px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  padding: "10px; /* Optional: Add padding",
  marginTop: "100px",
  cursor: "pointer",
};

const Ping = () => {
  const [fetchedNodes, setFetchedNodes] = useState([]);
  const [reportToView, setReportToView] = useState(null);
  const [clickreport, setClickReport] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [errorOnFetchNodes, setErrorOnFetchNodes] = useState(false);
  const [openDeleteDataModal, setOpenDeleteDataModal] = useState({
    open: false,
    data: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [pingStartDate, setPingStartDate] = useState(null);
  const [pingEndDate, setPingEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [noCountry, setNoCountry] = useState(false);

  const classes = classNames("d-flex", "my-auto", "date-range");

  const sitesStatus = useSelector((state) => state.sitesStatus.sitesStatus);
  const sites = useSelector((state) => state.sites.sites);
  const loading = useSelector((state) => state.sitesStatus.loading);
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  let pingData = useSelector((state) => state.pingData?.pingData);

  const loadingPingData = useSelector((state) => state.pingData?.loading);
  const loadingNewPingDataState = useSelector(
    (state) => state.newPingData?.loading
  );
  const errorOnPingData = useSelector((state) => state.pingData?.error);

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleTabChange = (e) => {
    localStorage.setItem("tabUrl", JSON.stringify(e.target.value));
    dispatch(getPingData(e.target.value));
    dispatch(getMetryVScoreData(e.target.value));
    dispatch(getOriginSummaryData(e.target.value));
    dispatch(getMonthlyOverallScoreData(e.target.value));
    dispatch(getRankingData(e.target.value));
    dispatch(getMonthlyLabData(e.target.value));
    dispatch(getUptimeStatusData(e.target.value));
    dispatch(getW3ValidatorData(e.target.value));
  };

  const fetchNodes = async () => {
    try {
      setFetchedNodes([]);
      setSelectedCountries([]);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_PING_URL}/get-nodes`
      );

      for (const [key, value] of Object.entries(data)) {
        setFetchedNodes((prev) => [
          ...prev,
          {
            host: `&node=${key}`,
            keyValue: key,
            country: value.server,
            ip_address: value.latency,
          },
        ]);

        setSelectedCountries((prev) => [...prev, `&node=${key}`]);
      }
      setOptions(data);
    } catch (error) {
      setFetchedNodes([]);
      setSelectedCountries([]);
      setErrorOnFetchNodes(true);
      console.log(error);
    }
  };

  console.log("fetchedNodes", fetchedNodes);

  const handleDateSelect = (startDate, endDate, site) => {
    try {
      if (startDate != null && endDate != null) {
        dispatch(getFilteredPingData(startDate, endDate, site));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const endDateRef = useRef(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleDateSelectt(date, endDate);
    if (endDateRef.current) {
      endDateRef.current.setOpen(true);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleDateSelectt(startDate, date);
  };

  const handleDateSelectt = (startDate, endDate) => {
    try {
      if (startDate != null && endDate != null) {
        dispatch(getFilteredPingData(startDate, endDate, sites[0].url));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePingDateSelect = async (startPingDate, endPingDate, site) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_PING_URL}/get-ping-data`,
        {
          startDate: startPingDate,
          endDate: endPingDate,
          site: site,
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (selectedList) => {
    setSelectedOptions(selectedList);
  };

  const handleClick = () => {
    if (selectedOptions.length == 0) {
      setNoCountry(true);
    } else {
      setIsOpen(true);
    }
  };
  const pingSite = (site, selectedCountries, startDate, endDate) => {
    function findHostsForServers(selectedOptions, fetchedNodes) {
      const hostsArray = selectedOptions.map((item) => {
        const serverData = fetchedNodes.find(
          (data) => data.country === item.server
        );
        return serverData ? serverData.host : "Host not found";
      });
      return hostsArray;
    }
    const hostsArray = findHostsForServers(selectedOptions, fetchedNodes);
    selectedCountries = hostsArray;

    const pingingSnackbar = enqueueSnackbar(`Ping on ${site} ...`, {
      variant: "info",
      persist: true,
    });
    dispatch(addNewPingData({ site, selectedCountries, startDate, endDate }))
      .then(() => {
        closeSnackbar(pingingSnackbar);
        const pingCompleted = enqueueSnackbar(
          `Ping completed successfully ${site}`,
          {
            variant: "success",
          }
        );
        setSelectedCountries([]);
        setTimeout(() => {
          closeSnackbar(pingCompleted);
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        const pingError = enqueueSnackbar(`Ping Failed ${site}`, {
          variant: "error",
        });
        setTimeout(() => {
          closeSnackbar(pingError);
        }, 5000);
        setSelectedCountries([]);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("tabUrl")) {
      const optionsarraylistuniqueclassping = document.querySelectorAll(
        ".optionsarraylistuniqueclassping"
      );
      optionsarraylistuniqueclassping.forEach((item) => {
        if (localStorage.getItem("tabUrl")) {
          if (`"${item.value}"` === localStorage.getItem("tabUrl")) {
            item.selected = "selected";
          }
        }
      });
      if (!pingData.length) {
        dispatch(
          getPingData(localStorage.getItem("tabUrl").replaceAll('"', ""))
        );
      }
    }
    if (!sitesStatus || !sites?.length) {
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.forEach((item) => {
              urls.push(item.url);
            });
            dispatch(getSitesStatusData(urls));
            localStorage.setItem("tabUrl", JSON.stringify(response[0].url));
            localStorage.setItem(
              "comparisonUrl",
              JSON.stringify(response[0].url)
            );
            dispatch(getPingData(response[0].url), startDate, endDate);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (
      !localStorage.getItem("tabUrl") &&
      (sitesStatus?.length || sites?.length)
    ) {
      localStorage.setItem("tabUrl", JSON.stringify(sites[0].url));
      dispatch(getPingData(sites[0].url));
      dispatch(getMetryVScoreData(sites[0].url));
      dispatch(getOriginSummaryData(sites[0].url));
      dispatch(getMonthlyOverallScoreData(sites[0].url));
      dispatch(getRankingData(sites[0].url));
      dispatch(getMonthlyLabData(sites[0].url));
      dispatch(getUptimeStatusData(sites[0].url));
      dispatch(getW3ValidatorData(sites[0].url));
      dispatch(getPingData(sites[0].url));
    }
    fetchNodes();
    handleDateSelect();
    dispatch(getUserPlanData(user.userId));
    if (endDateRef.current) {
      endDateRef.current.focus;
    }
  }, []);

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ position: "relative" }}
    >
      {
        <DeleteDataModal
          open={openDeleteDataModal}
          setOpen={setOpenDeleteDataModal}
        />
      }
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Ping Data"
          subtitle="ping"
          className="text-sm-left"
        />
      </Row>
      {!sites?.length && !loading ? (
        <Row className="no-sites-yet">
          <Col className="col-12">
            <p>
              There are no sites yet added from you. Please go ahead and try it
              out!
            </p>
          </Col>
          <Col className="col-12">
            <Link
              to={{
                pathname: "/registered-sites",
              }}
            >
              <Button className="d-inline-block btn-primary">Add Site</Button>
            </Link>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="col-lg mb-3 pl-0 pr-0 ml-0 mr-0">
            <Col className="col-lg-12 col-md-12 col-sm-12 pl-0 pr-0 ml-0 mr-0">
              <div>
                {reportToView ? (
                  <h4>Site url: {sites[0].url}</h4>
                ) : (
                  <FormSelect
                    className="overviewSelect"
                    id="feInputState"
                    onChange={handleTabChange}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : sitesStatus ? (
                      sitesStatus.map((site, idx) => (
                        <option
                          value={site.url}
                          key={idx}
                          className={"optionsarraylistuniqueclassping"}
                        >
                          {site.siteName ? site.siteName : site.url}
                        </option>
                      ))
                    ) : null}
                  </FormSelect>
                )}
              </div>
            </Col>
          </Row>
          {errorOnFetchNodes ? (
            <div
              className={`alert alert-danger`}
              style={{ borderRadius: "7px" }}
            >
              Something went wrong while fetching countries. Please try again
              later
            </div>
          ) : (
            !reportToView && (
              <>
                <Row
                  className="col-lg pl-0 pr-0 ml-0 mr-0"
                  style={{ justifyContent: "center" }}
                >
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <label
                      style={{
                        color: "#3D506E",
                        fontWeight: "600",
                        fontSize: "20px",
                        marginTop: "30px",
                      }}
                    >
                      Choose Countries
                    </label>
                  </div>
                </Row>
                <Row
                  className="col-lg mb-3 pl-0 pr-0 ml-0 mr-0"
                  style={{ justifyContent: "center" }}
                >
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Multiselect
                      options={options}
                      selectedValues={selectedOptions}
                      onSelect={handleSelect}
                      onRemove={handleSelect}
                      defaultValue={options[0]}
                      hidePlaceholder={true}
                      displayValue="server"
                      placeholder={noCountry ? "No options selected" : "Select"}
                      className={noCountry ? "custom-multiselect" : ""}
                    />
                    <Button
                      theme="primary"
                      className="d-inline-block btn-primary m-2"
                      onClick={handleClick}
                      style={{ background: "#0087F9", height: "35px" }}
                    >
                      Ping now
                    </Button>
                  </div>
                  <div>
                    <ReactModal
                      isOpen={isOpen}
                      style={{
                        content: {
                          width: "480px",
                          height: "350px",
                          marginLeft: "500px",
                          marginTop: "150px",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <div>
                        <div style={{ fontSize: "26px" }}>
                          Choose the interval of time that you want to ping site
                        </div>
                        <hr />
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <label
                            style={{
                              width: "52%",
                              color: "#3D506E",
                              fontSize: "15px",
                            }}
                          >
                            From
                          </label>
                          <label
                            style={{
                              width: "30%",
                              color: "#3D506E",
                              fontSize: "15px",
                            }}
                          >
                            To
                          </label>
                        </div>
                        <InputGroup className={classes}>
                          <DatePicker
                            size="sm"
                            selected={pingStartDate}
                            onChange={(date) => setPingStartDate(date)}
                            onSelect={() => handlePingDateSelect(pingStartDate)}
                            placeholderText="Start Date"
                            dropdownMode="select"
                            className="text-center"
                            dateFormat="yyyy-MM-dd"
                            startTime="08:00"
                            minDate={Date.now()}
                            style={{
                              paddingRight: "10px",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                            popperPlacement="top-start"
                            popperContainer={CalendarContainer}
                          />
                          <InputGroupAddon
                            type="append"
                            style={{ paddingRight: "50px", cursor: "pointer" }}
                          ></InputGroupAddon>
                          <DatePicker
                            size="sm"
                            selected={pingEndDate}
                            onChange={(date) => setPingEndDate(date)}
                            onSelect={() =>
                              handlePingDateSelect(
                                pingStartDate,
                                pingEndDate,
                                sites[0].url
                              )
                            }
                            placeholderText="End Date"
                            dropdownMode="select"
                            dateFormat="yyyy-MM-dd"
                            startTime="23:00"
                            minDate={Date.now()}
                            className="text-center"
                            popperPlacement="top-start"
                            popperContainer={CalendarContainer}
                          />
                        </InputGroup>
                        <div style={{ marginTop: "30px", marginLeft: "208px" }}>
                          <Button
                            theme="danger"
                            className="d-inline-block btn-primary m-2"
                            style={{
                              backgroundColor: "white",
                              color: "#C4183C",
                            }}
                            onClick={() => setIsOpen(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            theme="primary"
                            className="d-inline-block btn-primary m-2"
                            style={{ background: "#0087F9" }}
                            onClick={() => {
                              pingSite(
                                localStorage
                                  .getItem("tabUrl")
                                  ?.replaceAll('"', "")
                                  ? localStorage
                                      .getItem("tabUrl")
                                      ?.replaceAll('"', "")
                                  : sitesStatus[0].siteName,
                                selectedCountries,
                                pingStartDate,
                                pingEndDate
                              ),
                                setIsOpen(false);
                            }}
                            disabled={
                              !selectedCountries.length ||
                              loadingNewPingDataState
                            }
                          >
                            Ping now
                          </Button>
                        </div>
                      </div>
                    </ReactModal>
                  </div>
                </Row>
              </>
            )
          )}
          {clickreport && (
            <ChartsTable
              sitesStatus={sitesStatus}
              reportToView={reportToView}
              pingData={pingData}
            />
          )}
          {errorOnPingData ? (
            <div
              className={`alert alert-danger`}
              style={{ borderRadius: "7px" }}
            >
              Something went wrong while fetching ping data. Please try again
              later
            </div>
          ) : (
            <Row>
              <Col className="col-lg-12 col-md-12 col-sm-12">
                {!reportToView ? (
                  <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h6 className="m-0" style={{ width: "200px" }}>
                          Ping data by date
                        </h6>
                        {!clickreport && (
                          <div className="container">
                            <div className="center">
                              <div
                                className="form-group"
                                style={{
                                  width: "50%",
                                  cursor: "pointer",
                                }}
                              ></div>
                              <div
                                style={{
                                  paddingLeft: "50%",
                                }}
                              >
                                <DatePickerr
                                  selected={startDate}
                                  onChange={handleStartDateChange}
                                  placeholderText="Start Date"
                                  style={styles}
                                  dateFormat="yyyy-MM-dd"
                                  popperPlacement="bottom-start"
                                  popperContainer={CalendarContainer}
                                />
                                <DatePickerr
                                  selected={endDate}
                                  onChange={handleEndDateChange}
                                  placeholderText="End Date"
                                  ref={endDateRef}
                                  dateFormat="yyyy-MM-dd"
                                  style={styles}
                                  popperPlacement="bottom-start"
                                  popperContainer={CalendarContainer}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <Button
                          disabled={
                            startDate == null && endDate == null ? true : false
                          }
                          onClick={() => {
                            dispatch(
                              getPingData(
                                localStorage
                                  .getItem("tabUrl")
                                  .replaceAll('"', "")
                              )
                            ),
                              setStartDate(null);
                            setEndDate(null);
                          }}
                          style={{ marginRight: "22px" }}
                        >
                          Reset{" "}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="p-0 pb-3 registered-sites">
                      {loadingPingData ? (
                        <CircularProgress />
                      ) : isMobile ? (
                        <TableMobile
                          pingData={pingData}
                          setReportToView={setReportToView}
                          setClickReport={setClickReport}
                          setOpenDeleteDataModal={setOpenDeleteDataModal}
                        />
                      ) : (
                        <Table
                          pingData={pingData}
                          setReportToView={setReportToView}
                          setClickReport={setClickReport}
                          setOpenDeleteDataModal={setOpenDeleteDataModal}
                        />
                      )}
                    </CardBody>
                  </Card>
                ) : (
                  <Card small className="mb-4">
                    <CardBody className="p-0 pb-3 registered-sites">
                      {isMobile ? (
                        <TableDataMobile
                          pingData={pingData}
                          reportToView={reportToView}
                        />
                      ) : (
                        ""
                      )}
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default Ping;
