import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormSelect,
} from "shards-react";
import { Radio, RadioGroup } from "react-radio-group";
import { useSelector, useDispatch } from "react-redux";
import {
  getMetryVScoreData,
  getOriginSummaryData,
  getMonthlyOverallScoreData,
  getRankingData,
  getMonthlyLabData,
  getUptimeStatusData,
  getW3ValidatorData,
} from "../../state/actions/psi.actions";
import { getPingData } from "../../state/actions/ping.actions";

const Settings = ({
  allNotifications,
  handleEmailNotificationsChange,
  handleSlackNotificationsChange,
  handleDiscordNotificationsChange,
  handleNotificationFrequency,
}) => {
  const userEmail = localStorage.getItem("user");
  const sitesStatus = useSelector((state) => state.sitesStatus.sitesStatus);
  const dispatch = useDispatch();

  const handleTabChange = (e) => {
    dispatch(getMetryVScoreData(e.target.value));
    dispatch(getOriginSummaryData(e.target.value));
    dispatch(getMonthlyOverallScoreData(e.target.value));
    dispatch(getRankingData(e.target.value));
    dispatch(getMonthlyLabData(e.target.value));
    dispatch(getUptimeStatusData(e.target.value));
    dispatch(getW3ValidatorData(e.target.value));
    dispatch(getPingData(e.target.value));

    localStorage.setItem("tabUrl", JSON.stringify(e.target.value));
  };

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            {!sitesStatus?.length ? (
              ""
            ) : (
              <Row className="col-lg mb-3 pl-0 pr-0 ml-0 mr-0">
                <FormSelect
                  className="overviewSelect"
                  id="feInputState"
                  onChange={handleTabChange}
                >
                  {!sitesStatus.length
                    ? ""
                    : sitesStatus.map((site, idx) => (
                        <option
                          value={site.url}
                          key={idx}
                          className={"optionsarraylistuniqueclass"}
                        >
                          {site.siteName ? site.siteName : site.url}
                        </option>
                      ))}
                </FormSelect>
              </Row>
            )}
          </CardHeader>
          <CardBody className="p-0 pb-3 registered-notifications">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Type
                  </th>
                  <th scope="col" className="border-0">
                    Where
                  </th>
                  <th scope="col" className="border-0"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>
                    {allNotifications?.email
                      ? allNotifications.email.userEmail
                      : userEmail}
                  </td>
                  <td>
                    <fieldset>
                      <FormCheckbox
                        toggle
                        small
                        checked={
                          allNotifications?.email
                            ? allNotifications.email.toggle_notification
                            : false
                        }
                        onChange={handleEmailNotificationsChange}
                      >
                        {allNotifications?.email
                          ? allNotifications.email.toggle_notification
                            ? "Enabled "
                            : "Disabled"
                          : "Disabled"}
                      </FormCheckbox>
                    </fieldset>
                  </td>
                  <td>
                    <RadioGroup
                      id="type"
                      name="type"
                      onChange={(frequency) =>
                        handleNotificationFrequency(frequency)
                      }
                    >
                      <div className="radio-button-background">
                        <Radio
                          value="Daily"
                          className="radio-button"
                          disabled={
                            allNotifications.email.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Daily
                      </div>
                      <div className="radio-button-background">
                        <Radio
                          value="Weekly"
                          className="radio-button"
                          disabled={
                            allNotifications.email.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Weekly
                      </div>
                      <div className="radio-button-background">
                        <Radio
                          value="Monthly"
                          className="radio-button"
                          disabled={
                            allNotifications.email.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Monthly
                      </div>
                    </RadioGroup>
                  </td>
                </tr>
                <tr>
                  <td>Slack</td>
                  <td>
                    {allNotifications?.slack?.incoming_webhook
                      ? allNotifications.slack.incoming_webhook.channel
                      : ""}
                  </td>
                  <td>
                    <fieldset>
                      <FormCheckbox
                        toggle
                        small
                        checked={
                          allNotifications?.slack
                            ? allNotifications.slack.toggle_notification
                            : false
                        }
                        onChange={handleSlackNotificationsChange}
                      >
                        {allNotifications?.slack
                          ? allNotifications.slack.toggle_notification
                            ? "Enabled "
                            : "Disabled"
                          : "Disabled"}
                      </FormCheckbox>
                    </fieldset>
                  </td>
                  <td>
                    <RadioGroup
                      id="type"
                      name="type"
                      onChange={(frequency) =>
                        handleNotificationFrequency(frequency)
                      }
                    >
                      <div className="radio-button-background">
                        <Radio
                          value="Daily"
                          className="radio-button"
                          disabled={
                            allNotifications.slack.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Daily
                      </div>
                      <div className="radio-button-background">
                        <Radio
                          value="Weekly"
                          className="radio-button"
                          disabled={
                            allNotifications.slack.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Weekly
                      </div>
                      <div className="radio-button-background">
                        <Radio
                          value="Monthly"
                          className="radio-button"
                          disabled={
                            allNotifications.slack.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Monthly
                      </div>
                    </RadioGroup>
                  </td>
                </tr>
                <tr>
                  <td>Discord</td>
                  <td>
                    {allNotifications?.discord
                      ? allNotifications.discord.webhook_url
                      : ""}
                  </td>
                  <td>
                    <fieldset>
                      <FormCheckbox
                        toggle
                        small
                        checked={
                          allNotifications?.discord
                            ? allNotifications.discord.toggle_notification
                            : false
                        }
                        onChange={handleDiscordNotificationsChange}
                      >
                        {allNotifications?.discord
                          ? allNotifications.discord.toggle_notification
                            ? "Enabled "
                            : "Disabled"
                          : false}
                      </FormCheckbox>
                    </fieldset>
                  </td>
                  <td>
                    <RadioGroup
                      id="type"
                      name="type"
                      onChange={(frequency) =>
                        handleNotificationFrequency(frequency)
                      }
                    >
                      <div className="radio-button-background">
                        <Radio
                          value="Daily"
                          className="radio-button"
                          disabled={
                            allNotifications.discord.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Daily
                      </div>
                      <div className="radio-button-background">
                        <Radio
                          value="Weekly"
                          className="radio-button"
                          disabled={
                            allNotifications.discord.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Weekly
                      </div>
                      <div className="radio-button-background">
                        <Radio
                          value="Monthly"
                          className="radio-button"
                          disabled={
                            allNotifications.discord.toggle_notification
                              ? false
                              : true
                          }
                        />
                        Monthly
                      </div>
                    </RadioGroup>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Settings;
