// Dashboard Page
export const GET_ORIGIN_SUMMARY = "GET_ORIGIN_SUMMARY";
export const GET_SITES_LIST = "GET_SITES_LIST";
export const GET_MONTHLY_OVERALL_SCORE = "GET_MONTHLY_OVERALL_SCORE";
export const GET_MONTHLY_LAB_DATA = "GET_MONTHLY_LAB_DATA";

export const GET_DATA_SEARCH = "GET_DATA_SEARCH";

export const ADD_SITE = "ADD_SITE";
export const REMOVE_SITE = "REMOVE_SITE";
export const GET_SITE_STATUS = "GET_SITE_STATUS";

export const MAKE_PSI_SCAN = "MAKE_PSI_SCAN";
export const TRIGGER_PSI_SCAN = "TRIGGER_PSI_SCAN";

export const GET_PAGES_LIST = "GET_PAGES_LIST";
export const ADD_PAGE = "ADD_PAGE";
export const ADD_SINGLE_PAGE = "ADD_SINGLE_PAGE";
export const REMOVE_PAGE = "REMOVE_PAGE";
export const GET_PAGE_STATUS = "GET_PAGE_STATUS";
